.header {
    background-color: white;
    height: 97px;
    padding-inline: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header_right {
    display: flex;
    gap: 80px;
}

.header_nav {
    display: flex;
    gap: 50px;
}

.header_logo img {
    width: 120px;
    height: 65px;
}

.header_nav_list {
    display: flex;
    align-items: center;
    gap: 50px;
}

.herder_nav_list_item a {
    color: #333333;
    font-weight: 400;
    font-size: 20px;
    font-family: 'TTTravels-Medium', sans-serif;
}

.header_btn {
    display: flex;
    align-items: center;
    gap: 37px;
}

.header_btn a {
    background-color: transparent;
    padding: 10px;
    color: white;
    font-size: 16px;
    font-family: 'TTTravels-Medium', sans-serif;
    font-weight: 600;
    background-color: #333333;
    border-radius: 10px;
}

.header_btn_lang {
    display: flex;
    padding: 8px;
    gap: 8px;
    height: 44px;
    background-color: #333333;
    border-radius: 10px;
}

.header_btn_lang_en {
    color: #ffffff;
    font-size: 16px;
    font-family: 'TTTravels-Medium', sans-serif;
    font-weight: 600;
    background-color: transparent;
    border: none;
}

.header_btn_lang_trk {
    color: #ffffff;
    font-size: 16px;
    font-family: 'TTTravels-Medium', sans-serif;
    font-weight: 600;
    background-color: transparent;
    border: none;
}

.active {
    color: #000000;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 3px 5px;
}


@media (min-width: 1024px) and (max-width: 1439px) {
    .header {
        padding-inline: 40px;
    }

    .header_nav_list {
        gap: 20px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .header {
        background-color: white;
        height: 97px;
        padding-inline: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .header_nav_list {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .header_right {
        display: flex;
        gap: 20px;
    }

    .herder_nav_list_item a {
        color: #333333;
        font-weight: 400;
        font-size: 18px;
        font-family: 'TTTravels-Medium', sans-serif;
    }
}