.hero {
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    position: relative;
}

.hero_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 793px;
    margin: 95px 320px 140px;
}

.hero_text h1 {
    font-family: "Poppins", sans-serif;
    font-size: 50px;
    color: #F8F8FF;
    font-weight: 500;
    text-align: center;
    line-height: 100%;
}

.hero_text p {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #F8F8FF;
    font-weight: 300;
    text-align: center;
    margin-top: 56px;
}

.hero_text a {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #333333;
    font-weight: 600;
    background-color: #F8F8FF;
    border-radius: 10px;
    width: 194px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 28px;
}

.hero img {
    position: absolute;
    left: 0;
    bottom: -200px;
}

.bg {
    z-index: 1;
    background-size: cover;
    background-color: #333333;
}

@media (min-width: 1024px) and (max-width: 1439px) {
    .hero_text {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 793px;
        margin: 95px 0px 140px;
    }

    .hero img {
        position: absolute;
        left: 0;
        bottom: -120px;
        width: 180px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .hero_text {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 700px;
        margin: 95px 20px 140px;
    }

    .hero_text h1 {
        font-size: 30px;
    }

    .hero_text p {
        margin-top: 35px;
    }

    .hero img {
        position: absolute;
        left: 0;
        bottom: -120px;
        width: 180px;
    }
}