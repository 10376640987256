.product_hero {
    height: 554px;
    display: flex;
    justify-content: center;

}

.product_hero h1 {
    padding-top: 50px;
    color: #F8F8FF;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 135px;
}

.product_list {
    background-color: #333333;
    padding: 55px 142px 152px;
    display: flex;
    flex-direction: column;
    gap: 84px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
}

.product_list1 {
    display: flex;
    flex-direction: column;
    gap: 26px;
}

.product_list_block1 {
    display: flex;
    flex-direction: column;
    gap: 26px;
}

.product_list_block1 h1 {
    font-family: "Poppins", sans-serif;
    color: #F8F8FF;
    font-size: 26px;
    font-weight: 800;

}

.product_list_block1 p {
    font-family: "Poppins", sans-serif;
    color: #F8F8FF;
    font-size: 18px;
    font-weight: 300;
}

.product_list_block2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.product_list_block2 ul {
    display: flex;
    flex-direction: column;
    gap: 22px;
    width: 470px;
}

.product_list_block2 li {
    font-family: "Poppins", sans-serif;
    list-style: disc;
    color: #F8F8FF;
    font-size: 18px;
    font-weight: 300;
}

.product_list_block2 strong {
    font-family: "Poppins", sans-serif;
    color: #F8F8FF;
    font-size: 18px;
    font-weight: 800;
}

.box1 {
    position: absolute;
    top: -180px;
    right: 0;
}

.box2 {
    position: absolute;
    bottom: -130px;
    left: 0;
    width: 200px;
}