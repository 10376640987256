.service {
	padding-top: 46px;
	padding-inline: 195px;
}



.service_block {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.service_text_block h1 {
	font-family: "Poppins", serif;
	text-align: center;
	font-weight: 600;
	font-size: 35px;
	color: #F8F8FF;
}

.service_text_block p {
	font-family: "Poppins", serif;
	text-align: center;
	font-weight: 400;
	font-size: 18px;
	color: #F8F8FF;
	padding-top: 58px;
}

.service_card_block {
	display: flex;
	gap: 60px;
	padding-top: 98px;
	justify-content: center;
}

.service_card {
	display: flex;
	align-items: center;
	gap: 20px;
}

.service_card_text span {
	color: #F8F8FF;
	font-family: "Krub", sans-serif;
	font-size: 24px;
	font-weight: 600;
}

.service_card_text p {
	color: #F8F8FF;
	font-family: "Krub", sans-serif;
	font-size: 18px;
	font-weight: 300;
}

@media (min-width: 1024px) and (max-width: 1439px) {
	.service {
		padding-top: 46px;
		padding-inline: 40px;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.service {
		padding-top: 46px;
		padding-inline: 40px;
	}

	.service_card_block {
		display: flex;
		gap: 60px;
		padding-top: 55px;
		padding-inline: 23%;
		flex-wrap: wrap;
		justify-content: flex-start;
	}
}