.contact {
    margin-top: 65px;
    padding-bottom: 84px;
    display: flex;
    justify-content: center;
}

.contact_left_block {
    width: 452px;
}

.contact_left_block h1 {
    font-family: "Rubik", sans-serif;
    font-size: 35px;
    font-weight: 600;
    color: #F8F8FF;
    text-align: center;
}

.contact_left_block p {
    margin-top: 74px;
    color: #F8F8FF;
    font-family: "Poppins", serif;
    font-size: 18px;
    text-align: center;
    font-weight: 400;
}

.contact_left_block form {
    margin-top: 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.contact_inp_name {
    height: 30px;
    width: 256px;
    padding-left: 20px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #F8F8FF;
    font-family: "Poppins", serif;
    font-size: 16px;
    font-weight: 400;
    color: #F8F8FF;
    background-color: transparent;
}

.contact_inp_phone {
    height: 30px;
    width: 256px;
    padding-left: 20px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #F8F8FF;
    font-family: "Poppins", serif;
    font-size: 16px;
    font-weight: 400;
    color: #F8F8FF;
    background-color: transparent;
}

.contact_left_block input:focus {
    outline: none;
}

.contact_left_block button {
    width: 194px;
    height: 60px;
    background-color: #F8F8FF;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Krub", sans-serif;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 10px;
}


