.footer {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 306px;
}

.footer_up {
    margin-inline: 80px;
    padding: 37px 0px;
    display: flex;
    justify-content: space-between;
    flex: 1 auto;
}

.footer_up_left {
    display: flex;
    gap: 45px;
}

.footer_logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer_logo span {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    color: #333333;
    font-size: 14px;
}

.footer_loc {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 270px;
}

.footer_loc span {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    font-family: "Inter", sans-serif;
}


.footer_number_email {
    gap: 6px;
    display: flex;
    flex-direction: column;
}

.footer_number_email span {
    font-size: 16px;
    color: #333333;
    font-weight: 400;
    font-family: "Poppins", serif;
}

.footer_number_email a {
    font-size: 16px;
    color: #333333;
    font-weight: 400;
    font-family: "Poppins", serif;
}

.footer_nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 56px;
}

.footer_nav a {
    font-size: 16px;
    color: #333333;
    font-weight: 400;
    font-family: "Poppins", serif;
}

.footer_nav span {
    font-size: 16px;
    color: #333333;
    font-weight: 600;
    font-family: "Poppins", serif;
}


.footer_down span {
    text-align: center;
    font-size: 12px;
    color: #333333;
    font-weight: 400;
    font-family: "Poppins", serif;
}

.footer_down {
    padding-top: 22px;
    padding-bottom: 36px;
    border-top: 1px solid #333333;
    margin-inline: 75px;
    display: flex;
    justify-content: center;
}

.footer_down span {
    color: #333333;
    font-family: "Poppins", serif;
    font-size: 12px;
}

@media (min-width: 1024px) and (max-width: 1439px) {
    .footer_nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .footer_up {
        margin-inline: 40px;
        display: flex;
        justify-content: space-between;
        flex: 1 1 auto;
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }
}