.about {
	padding-inline: 156px;
	padding-top: 43px;
}

.about_block1 {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.about_block1 h1 {
	font-family: "Poppins", serif;
	text-align: center;
	font-weight: 600;
	font-size: 35px;
	color: #F8F8FF;
}

.about_block1 p {
	font-family: "Poppins", serif;
	text-align: center;
	font-weight: 400;
	font-size: 18px;
	color: #F8F8FF;
	width: 940px;
}

.about_block2 {
	display: flex;
	gap: 29px;
}

.about_block2_title {
	padding-top: 27px;
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.about_block2_title h2 {
	font-family: "Poppins", serif;
	font-weight: 500;
	font-size: 20px;
	color: #F8F8FF;
}

.about_block2_title p {
	font-family: "Poppins", serif;
	font-weight: 400;
	font-size: 18px;
	color: #F8F8FF;
}


.about_block3 {
	padding-top: 64px;
}

.about_block3 h3 {
	font-family: "Poppins", serif;
	font-weight: 500;
	font-size: 20px;
	color: #F8F8FF;
	text-align: center;
}

.about_block3_list {
	padding-top: 40px;
	display: flex;
	flex-direction: column;
	gap: 47px;
}

.about_block3_list ul {
	display: flex;
	justify-content: center;
	gap: 75px;
}

.about_block3_list li {
	list-style: disc;
	font-family: "Poppins", serif;
	font-weight: 400;
	font-size: 18px;
	color: #F8F8FF;
	width: 470px;
}

.about_block3_list strong {
	font-family: "Poppins", serif;
	font-weight: 800;
	font-size: 18px;
	color: #F8F8FF;
}

@media (min-width: 1024px) and (max-width: 1439px) {
	.about {
		padding-inline: 40px;
		padding-top: 43px;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.about {
		padding-inline: 30px;
		padding-top: 43px;
	}

	.about_block1 p {
		width: 700px;
	}

	.about_block3_list {
		padding-top: 40px;
		display: flex;
		flex-direction: column;
		gap: 47px;
		padding-inline: 35px;
	}
}